<ng-template #customLicenseMusicRequestModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="closeModal()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        [classGroup]="'squaredHover'"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div class="sos-generic-modal-container sos-scrollbar-none !text-primary">
      <div class="flex flex-col lg:flex-row ml-0 mr-0">
        <div class="pl-12 pr-10 w-full lg:w-[50%]">
          <h1 class="text-center mt-0 pb-4 text-4xl lg:text-2xl">
            Discover the SoStereo <br />Advantage
          </h1>
          <div
            class="flex relative left-[45px] justify-center pb-5 [&>img]:h-[45px] [&>img]:lg:h-[70px] [&>img]:rounded-full [&>img]:relative"
          >
            <div
              class="flex [&>img]:h-[45px] [&>img]:lg:h-[70px] [&>img]:rounded-full [&>img]:relative"
            >
              <img
                class="right-[15px]"
                src="./assets/images/about_us/team-pics/beto_azout.webp"
                alt="Beto Azout"
              />
              <img
                class="right-[30px] flip-img-h"
                src="./assets/images/about_us/team-pics/lindsey_camerota.webp"
                alt="Lindsey Camerota"
              />
              <img
                class="right-[45px] flip-img-h"
                src="./assets/images/about_us/team-pics/martin_gutierrez.webp"
                alt="Martin Gutierrez"
              />
              <img
                class="right-[60px] flip-img-h"
                src="./assets/images/about_us/team-pics/elaine_sir.webp"
                alt="Elaine Sir"
              />
              <img
                class="right-[75px]"
                src="./assets/images/about_us/team-pics/elijah_torn.webp"
                alt="Elijah Torn"
              />
            </div>
          </div>
          <div class="hidden lg:!block">
            <p>
              SoStereo is known for our dependable service and dedication to excellence in providing
              music solution for brands, agencies, films and more.
              <br />
              <br />
              We work with award-winning record-label artists to find just the right sonic fit for
              your marketing.
              <br />
              <br />
              From thousands of pre-cleared songs to custom music to scalable enterprise solutions,
              real artists can do it all.
            </p>
            <h3 class="mt-8 mb-0 font-semibold text-xl">A preferred minority-owned vendor for:</h3>
            <div class="flex justify-between items-center grayscale mt-2.5 [&>img]:w-[60px]">
              <img src="./assets/images/license_logos/wpp.webp" alt="WPP" />
              <img src="./assets/images/license_logos/publicis-small.webp" alt="Publicis" />
              <img src="./assets/images/license_logos/omnicom-small.webp" alt="Omnicom" />
              <img src="./assets/images/license_logos/ipg.webp" alt="IPG" />
              <img
                src="./assets/images/license_logos/stagwell-logo-small.webp"
                alt="Stagwell Partners"
                style="padding: 12px"
              />
            </div>
          </div>
        </div>
        <div
          class="w-full lg:w-[50%]"
          [ngClass]="domainName === 'sostereo' ? 'lg:w-[50%]' : 'w-full'"
        >
          <div
            class="modal-body"
            data-cy="custom-modal-license"
            *ngIf="!requestSuccess"
            [@inOutAnimation]
          >
            <div class="ml-0 mr-0">
              <div class="w-full">
                <h2 class="text-left mt-0 pb-4 text-4xl lg:text-2xl" style="display: inline">
                  Licensing Terms
                </h2>
                @if (isAllowed(['*', 'ListTiers'], []) && !licenseAssignation?.initOwnerUid &&
                domainName === 'sostereo' && !transactionComplete) {
                <div class="ml-5" style="display: inline; position: relative; bottom: 3px">
                  <a
                    (click)="chooseUserUID()"
                    class="underline hover:underline text-primary hover:!text-primary font-normal text-[14px] lg:text-base"
                    >Admin License assignation</a
                  >
                </div>
                }
                <div *ngIf="licenseAssignation?.initOwnerUid" class="mt-5 info-alert">
                  <p style="font-size: 16px">
                    This license will be assigned to
                    <span class="fw500">{{ licenseAssignation.ownerName }}</span>
                    <span *ngIf="licenseAssignation?.initGroups?.length > 0">
                      in the company
                      <span class="fw500">{{ licenseAssignation.initGroups[0] }}</span>
                    </span>
                  </p>
                </div>
                <div *ngIf="track" class="my-3 text-primary font-normal text-[14px] lg:text-base">
                  <b class="font-semibold">Song: </b> {{ track.name }} ({{
                    track.songVersion || track.version
                  }}) by
                  {{ track?.artists[0]?.name || track?.artists[0]?.artistName }}
                  <br />
                  <span *ngIf="track.restriction && track.restriction !== ''">
                    <br />
                    <b>Artist's restrictions: </b> {{ track.restriction }}
                  </span>
                </div>
                <div *ngIf="!track" class="my-3">
                  <b>Project: </b> {{ transactionComplete?.project }} <br />
                  <b>Company: </b> {{ transactionComplete?.company }}
                  <br />
                </div>
                <p class="!my-3" *ngIf="!transactionInputFactors">
                  Please complete the form to request a license for this song.
                </p>
              </div>
            </div>
            <div class="row ml-0 mr-0">
              <div class="w-full">
                <form
                  [formGroup]="customLicenseRequestForm"
                  class="mt-0"
                  (keydown.enter)="$event.preventDefault()"
                >
                  <div class="row ml-0 mr-0">
                    <div class="w-full pl-0 pr-0">
                      <label for="customLicenseRequestFormCategory">Category *</label>
                      <span
                        class="material-icons text-dark !text-[15px] ml-[5px]"
                        [attr.data-icon]="'info'"
                        matTooltip="What type of project are you working on? Examples include:
                        Advertisement, TV Show, Major Studio Film, etc."
                        matTooltipClass="bg-tooltip"
                        [matTooltipPosition]="'right'"
                      >
                      </span>
                      <app-tags-input-dropdown
                        [errors]="customLicenseRequestForm.controls['category'].errors"
                        [touched]="customLicenseRequestForm.controls['category'].touched"
                        [options]="categories"
                        [currentSelections]="draftFields['category'] || null"
                        [placeholder]="'e.g. TV Show'"
                        [uniqueOption]="true"
                        [preventValues]="[';']"
                        (tagAdded)="addTag($event, 'category')"
                        (tagRemoved)="removeTag($event, 'category')"
                      ></app-tags-input-dropdown>
                    </div>
                  </div>
                  <div class="row ml-0 mr-0">
                    <div class="col-md-8 pl-0 pr-0">
                      <label for="customLicenseRequestFormMedia">Media *</label>
                      <span
                        class="material-icons text-dark !text-[15px] ml-[5px]"
                        [attr.data-icon]="'info'"
                        matTooltip="On what medium will this project be publicized? Examples include:
                        Internet, Social Media, Broadcast TV, Radio, Film, etc."
                        matTooltipClass="bg-tooltip"
                        [matTooltipPosition]="'right'"
                      >
                      </span>
                      <app-tags-input-dropdown
                        [errors]="customLicenseRequestForm.controls['media'].errors"
                        [touched]="customLicenseRequestForm.controls['media'].touched"
                        [options]="medias"
                        [currentSelections]="draftFields['media'] || null"
                        [placeholder]="'e.g. Online digital + social'"
                        [preventValues]="[';']"
                        (tagAdded)="addTag($event, 'media')"
                        (tagRemoved)="removeTag($event, 'media')"
                      ></app-tags-input-dropdown>
                    </div>
                    <div class="col-md-offset-1 col-md-3 pl-0 pr-0 pb-4" style="text-align: right">
                      <label class="mb-1 pr-2.5 col-xs-12 pl-0">Paid Media</label>
                      <label class="switch">
                        <input type="checkbox" formControlName="paidMedia" />
                        <span class="slider"></span>
                      </label>
                    </div>
                  </div>
                  <div class="row ml-0 mr-0" *ngIf="mediaOptions.length > 0">
                    <div class="form-group col-md-12 pl-0 pr-0 mb-5">
                      <label for="customLicenseRequestFormTerm" class="">Term *</label>

                      <span
                        class="material-icons text-dark text-[15px] ml-[5px]"
                        [attr.data-icon]="'info'"
                        matTooltip="How long will this project be live to the public?"
                        matTooltipClass="bg-tooltip"
                        [matTooltipPosition]="'right'"
                      ></span>
                      <div
                        class="row mt-1 mb-1 items-baseline"
                        *ngFor="let media of mediaOptions; let i = index"
                        style="display: flex"
                      >
                        <div class="col-md-4">
                          <div class="vertical-center font-secondary text-base text-primary">
                            {{ media.name }}
                          </div>
                        </div>
                        <div class="col-md-8">
                          <app-tags-input-dropdown
                            [options]="terms"
                            [errors]="customLicenseRequestForm.controls['term'].errors"
                            [touched]="customLicenseRequestForm.controls['term'].touched"
                            [currentSelections]="
                              draftFields['term']?.length > 0 ? [draftFields['term'][i]] : null
                            "
                            [placeholder]="'e.g. 3 months'"
                            [uniqueOption]="true"
                            [preventValues]="[';']"
                            (tagAdded)="addTag($event, 'term', i)"
                            (tagRemoved)="removeTag($event, 'term', i)"
                          ></app-tags-input-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row ml-0 mr-0 mb-5" *ngIf="mediaOptions.length > 0">
                    <div class="w-full">
                      <label for="customLicenseRequestFormTerritory">Territory *</label>
                      <span
                        class="material-icons text-dark text-[15px] ml-[5px]"
                        [attr.data-icon]="'info'"
                        matTooltip="In what territories will this project be publicized."
                        matTooltipClass="bg-tooltip"
                        [matTooltipPosition]="'right'"
                      ></span>
                    </div>
                    <div
                      class="row pt-1 pb-1 items-baseline"
                      *ngFor="let media of mediaOptions; let i = index"
                      style="display: flex"
                    >
                      <div class="col-md-4">
                        <div class="vertical-center font-secondary text-base text-primary">
                          {{ media.name }}
                        </div>
                      </div>
                      <div class="col-md-8">
                        <app-tags-input-dropdown
                          [options]="territories"
                          [errors]="customLicenseRequestForm.controls['territory'].errors"
                          [touched]="customLicenseRequestForm.controls['territory'].touched"
                          [currentSelections]="
                            draftFields['territory']?.length > 0
                              ? [draftFields['territory'][i]]
                              : null
                          "
                          [placeholder]="'e.g. USA'"
                          [uniqueOption]="true"
                          [preventValues]="[';']"
                          (tagAdded)="addTag($event, 'territory', i)"
                          (tagRemoved)="removeTag($event, 'territory', i)"
                        ></app-tags-input-dropdown>
                      </div>
                    </div>
                  </div>
                  <div class="row ml-0 mr-0">
                    <div class="col-md-6 pl-0 pr-0">
                      <app-input-field
                        label="Duration"
                        placeholder="e.g. 30s, 15s"
                        [required]="true"
                        [control]="getControl('duration')"
                        [errors]="customLicenseRequestForm.controls['duration'].errors"
                        tooltipMessage="How long will the song be playing in the project?"
                      ></app-input-field>
                    </div>
                    <div class="col-md-offset-1 col-md-5 pl-0 pr-0">
                      <app-input-field
                        label="# of unique Spots"
                        placeholder="Enter spots"
                        [required]="true"
                        [control]="getControl('spots')"
                        [errors]="customLicenseRequestForm.controls['spots'].errors"
                      ></app-input-field>
                    </div>
                  </div>
                  <div
                    class="row ml-0 mr-0"
                    *ngIf="
                      (draftFields?.category?.length > 0 &&
                        (draftFields?.category[0]?.toLowerCase()?.includes('film') ||
                          draftFields?.category[0]?.toLowerCase()?.includes('game'))) ||
                      customLicenseRequestForm
                        ?.get('category')
                        ?.value?.name?.toLowerCase()
                        ?.includes('film') ||
                      customLicenseRequestForm
                        ?.get('category')
                        ?.value?.name?.toLowerCase()
                        ?.includes('game')
                    "
                  >
                    <div class="form-group col-md-12 pl-0 pr-0">
                      <app-input-field
                        label="Use Type (Optional)"
                        placeholder="Enter the use type"
                        [control]="getControl('typeOfUse')"
                        [errors]="customLicenseRequestForm.controls['typeOfUse'].errors"
                        tooltipMessage="How will the song be used in this project? Examples include:
                        Background Vocal, Background Instrumental, Visual, Vocal, Theme, Closing Credits, etc."
                      ></app-input-field>
                    </div>
                  </div>

                  <div *ngFor="let alert of alerts">
                    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{
                      alert.message
                    }}</alert>
                  </div>
                  <div class="mr-0" [ngClass]="{ 'pull-right': transactionInputFactors }">
                    <div class="col-xs-12">
                      @if (!track) {
                      <app-btn (click)="updateData()" [disabled]="loadingRequest || invalidPrice">
                        Save changes
                      </app-btn>
                      }
                    </div>
                    <div
                      class="flex w-full justify-end"
                      style="text-align: right"
                      *ngIf="!editOnly"
                    >
                      @if (track) {
                      <app-btn (click)="onCustomLicenseRequestSubmit(false)"> Add </app-btn>
                      }
                      <app-btn
                        class="ml-[11px]"
                        [classGroup]="'transparent'"
                        (click)="onCustomLicenseRequestSubmit(true)"
                      >
                        {{
                          transactionAction ? transactionAction.label : 'Add & Continue to Request'
                        }}
                      </app-btn>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-body" *ngIf="false" [@inOutAnimation]>
            <div class="row text-center">
              <div class="col-md-12">
                <h4 class="modal-title text-center">
                  <span
                    style="font-size: 70px"
                    class="glyphicon glyphicon-ok-circle font-20x"
                  ></span>
                </h4>
                <h2>Licence Request Submitted</h2>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-md-12">
                <p>
                  An employee will contact you as soon as possible to define the custom contract for
                  you license request.
                </p>
                <br /><br />
              </div>
            </div>
            <div class="row text-center">
              <div class="col-md-12">
                <button
                  id="discoverBtn"
                  type="submit"
                  class="btn primary-btn mt-5 mb-5 md-btn"
                  (click)="closeModal()"
                >
                  Continue Searching
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-license-assignation-modal
  (sendAssignationData)="getAssignationData($event)"
  #licenseAssignationModalComponent
>
</app-license-assignation-modal>
