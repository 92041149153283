<ng-template #licenseMusicRequestModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="hideModal()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        [classGroup]="'squaredHover'"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div class="sos-generic-modal-container bg-default sos-scrollbar-none !text-primary">
      <div class="" *ngIf="trackInput && !track?.soId && !track?.uri" [@inOutAnimation]>
        <div class="row text-center">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <h2 class="">This is an external Song</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <br />
            <p>
              This song was submitted by an external source & its not available for licensing with
              SoStereo.
            </p>
            <br />
            <p *ngIf="track?.fileRepresentedBy">
              <b style="font-weight: 600">Represented By:</b> {{ track?.fileRepresentedBy }}
            </p>
            <p *ngIf="track?.senderCompany">
              <b style="font-weight: 600">Sender Company:</b> {{ track?.fileRepresentedBy }}
            </p>
            <p *ngIf="track?.senderName">
              <b style="font-weight: 600">Sender Name:</b> {{ track?.senderName }}
            </p>
            <p *ngIf="track?.senderEmail">
              <b style="font-weight: 600">Sender Email:</b> {{ track?.senderEmail }}
            </p>
          </div>
        </div>
      </div>
      <div class="pl-0 pr-0" *ngIf="!trackInput || track?.soId || track?.uri" [@inOutAnimation]>
        <div class="form-licensing">
          <div class="row ml-0 mr-0">
            <div class="col-xs-12 col-sm-12 col-md-12 pt-[20px]">
              <div class="flex pb-[10px]">
                <div class="ml-2.5 mr-5 my-auto" *ngIf="agreementProcess">
                  <img
                    class="logo"
                    style="width: 50px; height: auto"
                    [src]="logoPath"
                    onerror="this.src='./assets/images/logos/stereo-brands.png'"
                  />
                </div>
                <div
                  *ngIf="track"
                  style="display: inline-grid"
                  [ngStyle]="{ 'max-width': agreementProcess ? '80%' : '' }"
                >
                  <h1 class="mb-0 mt-0">
                    {{ track?.name || track?.fileName }} ({{ track?.songVersion }})
                    <span style="color: #cccccc">{{
                      track?.songVersion?.toLowerCase() !== 'instrumental' &&
                      track?.hasOwnProperty('vocals') &&
                      !track?.vocals
                        ? 'Inst.'
                        : ''
                    }}</span>
                  </h1>
                  <p
                    class="secondary-color mt-0 fs20 font-primary fw300 underline-none"
                    *ngIf="track?.artists"
                  >
                    <span style="color: rgb(107, 106, 106); text-transform: none">by</span>
                    {{ track?.artists[0]?.name || track?.artists[0]?.artistName }}
                  </p>
                </div>
                <div
                  *ngIf="!track"
                  style="display: inline-grid"
                  [ngStyle]="{ 'max-width': agreementProcess ? '80%' : '' }"
                >
                  <h1 class="mb-2.5 mt-0">
                    {{ transactionComplete?.project || 'Cart / New License Request' }}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="loadingLicenseSteps">
            <div class="loader mt-8 mb-8" align="center">
              <div class="lds-ripple">
                <div></div>
                <div></div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!loadingLicenseSteps">
            <ng-container *ngIf="!agreementProcess">
              <div
                *ngIf="isAllowed(['*', 'ListTiers'], []) && assignationData?.initOwnerUid"
                class="ml-4 mb-5 mr-4 info-alert"
              >
                <p style="font-size: 16px">
                  This license will be assigned to
                  <span class="fw500">{{ assignationData.ownerName }}</span>
                  <span *ngIf="assignationData?.initGroups?.length > 0">
                    in the company <span class="fw500">{{ assignationData.initGroups[0] }}</span>
                  </span>
                </p>
              </div>
              <div class="row ml-0 mr-0">
                <div class="col-xs-12 col-sm-12 col-md-12 terms-text">
                  <ng-container *ngIf="showLicenseTermsAlert">
                    <span
                      style="font-size: 35px"
                      class="fa fa-exclamation-triangle pull-left ml-1.5 mt-4"
                    ></span>
                    <p class="mt-2.5">
                      While this song is One-Stop, it is not within the
                      {{ subdomain | uppercase }}-SOSTEREO agreement, and therefore is not
                      guaranteed to have the same pricing structure.
                    </p>
                    <hr />
                  </ng-container>
                  <div class="clearfix" *ngIf="!showSteps && !showLicenseTerms" [@inOutAnimation]>
                    <h6 class="mb-5">What type of project are you working on?</h6>
                    <div
                      class="col-xs-12 col-sm-12 col-md-12"
                      *ngFor="let type of types"
                      (click)="selectType(type)"
                    >
                      <label>
                        <input
                          style="margin: 5px 10px; float: left"
                          type="radio"
                          [checked]="type.selected"
                        />{{ type.label }}
                      </label>
                    </div>
                  </div>
                  <div class="clearfix" *ngIf="showSteps">
                    <h5 class="mb-2.5">
                      <b>{{ selectedType.steps[currentStepIndex].title }}</b>
                    </h5>
                    <div class="row ml-0 mr-0 mt-5 mb-5">
                      <div class="col-xs-12 col-sm-12 col-md-12">
                        <progressbar
                          [value]="selectedType.steps[currentStepIndex].progress"
                        ></progressbar>
                      </div>
                    </div>
                    <h5 class="mb-5">{{ selectedType.steps[currentStepIndex].description }}</h5>
                    <div
                      class="col-xs-12 col-sm-12 col-md-12"
                      *ngFor="let option of selectedType.steps[currentStepIndex].options"
                      (click)="selectOption(option)"
                    >
                      <label>
                        <input
                          style="margin: 5px 10px; float: left"
                          type="radio"
                          [checked]="option.selected"
                        />{{ option.label }}
                      </label>
                    </div>
                  </div>
                  <div class="clearfix" *ngIf="showLicenseTerms && licenseTerms" [@inOutAnimation]>
                    <div class="row text-left mt-0">
                      <div class="col-xs-12 col-sm-12 col-md-12 overflow-auto">
                        <div class="form-group">
                          <h5 class="mt-0">Terms</h5>
                          <ol class="pl-5">
                            <li *ngIf="commonService?.tenantId === 'sostereo'">
                              <strong>Licensor: </strong>SoStereo Music Group LLC
                            </li>
                            <ng-container *ngFor="let prop of licenseTerms | keyvalue">
                              <li [hidden]="prop.key === 'maxBudget'" *ngIf="prop.value.title">
                                <span *ngIf="prop.value.title">
                                  <strong>{{ prop.value.title }}: &nbsp;</strong
                                  >{{ prop.value.label }}
                                </span>
                              </li>
                            </ng-container>
                            <li>
                              <strong>Number of spots: </strong> {{ licenseTerms.spots || '-' }}
                            </li>
                          </ol>
                        </div>
                        <ng-container
                          *ngIf="
                            transactionInputFactors &&
                            track?.restriction &&
                            track?.restriction !== ''
                          "
                        >
                          <br />
                          <p>
                            <span class="fw500">Artist's restrictions:</span>
                            {{ track.restriction }}
                          </p>
                        </ng-container>
                        <div
                          class="form-group mt-8 mb-0"
                          [ngClass]="{
                            'pull-right': transactionInputFactors,
                            'text-center': !transactionInputFactors
                          }"
                        >
                          <button
                            class="mr-2.5 btn outline-primary-btn mb-4"
                            *ngIf="transactionInputFactors && !trackInput"
                            (click)="updateData()"
                            [disabled]="loadingRequest || invalidPrice"
                          >
                            Save changes
                            <i class="spin medium" *ngIf="editSpinActive"></i>
                          </button>
                          <ng-container *ngIf="!editOnly">
                            <button
                              class="mr-2.5 btn outline-primary-btn mb-4"
                              *ngIf="track"
                              (click)="sendAgreementLicenseRequest(false)"
                            >
                              Add and continue search
                              <i class="spin medium" *ngIf="editSpinActive"></i>
                            </button>
                            <button
                              class="btn primary-btn mb-4"
                              (click)="sendAgreementLicenseRequest(true)"
                              [disabled]="loadingRequest || invalidPrice"
                            >
                              {{
                                transactionAction
                                  ? transactionAction.label
                                  : 'Continue to License Request'
                              }}
                              <i class="spin medium" *ngIf="submitSpinActive"></i>
                            </button>
                            <br />
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <button
                      *ngIf="(selectedType && showSteps) || showLicenseTerms"
                      [@inOutAnimation]
                      type="button"
                      class="btn secondary-link mb-0 pull-left pl-1"
                      data-cy="prev-license"
                      (click)="prevStep()"
                    >
                      <i
                        class="fa fa-angle-left secondary-color"
                        aria-hidden="true"
                        style="font-size: 17px; font-weight: bold; padding-right: 5px"
                      ></i>
                      {{
                        transactionInputFactors && showLicenseTerms ? 'Go to Edit license' : 'Prev'
                      }}
                    </button>
                    <button
                      *ngIf="
                        this.selectedType?.slug === 'custom' ||
                        this.currentStepIndex !== this.selectedType?.steps?.length - 1
                      "
                      data-cy="next-license"
                      type="button"
                      class="btn secondary-link mb-0 pull-right pr-1"
                      [disabled]="
                        !selectedType || (this.showSteps && currentStepHasNotOptionSelected())
                      "
                      (click)="nextStep()"
                    >
                      Next
                      <i
                        class="fa fa-angle-right secondary-color"
                        aria-hidden="true"
                        style="font-size: 17px; font-weight: bold; padding-left: 5px"
                      ></i>
                    </button>
                    <button
                      type="button"
                      class="btn secondary-link mb-0 pull-right"
                      data-cy="continue-license"
                      *ngIf="
                        this.currentStepIndex === this.selectedType?.steps.length - 1 &&
                        !showLicenseTerms
                      "
                      [disabled]="currentStepHasNotOptionSelected()"
                      (click)="endProcess()"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="agreementProcess">
              <div class="row ml-0 mr-0">
                <div class="col-xs-12 col-sm-12 col-md-12 terms-text">
                  <ng-container *ngIf="showLicenseTermsAlert">
                    <span
                      style="font-size: 35px"
                      class="fa fa-exclamation-triangle pull-left ml-1.5 mt-4"
                    ></span>
                    <p class="mt-2.5">
                      While this song is One-Stop, it is not within the
                      {{ subdomain | uppercase }}-SOSTEREO agreement, and therefore is not
                      guaranteed to have the same pricing structure.
                    </p>
                    <hr />
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !(
                        showLicenseTermsAlert &&
                        !isAllowed([], ['UpdateTransaction:RestrictedLicenseRequester'])
                      )
                    "
                  >
                    <div *ngIf="isAllowed(['CreateTransaction:Credit'])">
                      <h5>{{ subdomain | titlecase }} Song Request Form</h5>
                      <p class="mt-2.5">
                        Please confirm the song request below, once submitted the
                        {{ subdomain | titlecase }} team will review and approve the usage ASAP. If
                        you have any questions please reach out to
                        <a
                          class="underline-none contact-text"
                          href="mailto:hello@sostereo.com"
                          target="_top"
                          >hello&#64;sostereo.com</a
                        >
                      </p>
                    </div>
                    <div
                      *ngIf="isAllowed(['*', 'ListTiers'], []) && assignationData?.initOwnerUid"
                      class="mt-5 info-alert"
                    >
                      <p style="font-size: 16px">
                        This license will be assigned to
                        <span class="fw500">{{ assignationData.ownerName }}</span>
                        <span *ngIf="assignationData?.initGroups?.length > 0">
                          in the company
                          <span class="fw500">{{ assignationData.initGroups[0] }}</span>
                        </span>
                      </p>
                    </div>
                    <div *ngIf="!isAllowed(['CreateTransaction:Credit'])">
                      <h5>{{ subdomain | titlecase }} Customized One-Stop Licensing Terms</h5>
                      <p class="mt-2.5">
                        Once submitted, a SoStereo representative will reach out ASAP (within 24
                        hours) to finalize and confirm the usages & send over the executed Music
                        License Agreement. If you have any questions, please reach out to
                        <a
                          class="underline-none contact-text"
                          href="mailto:hello@sostereo.com"
                          target="_top"
                          >hello&#64;sostereo.com</a
                        >
                      </p>
                    </div>

                    <hr />
                    <div class="clearfix" *ngIf="!showAgreementLicenseTerms" [@inOutAnimation]>
                      <h5 class="mb-5">
                        {{ agreementSteps[currentAgreementStepIndex]?.description }}
                      </h5>
                      <ng-container *ngIf="agreementSteps[currentAgreementStepIndex]?.multiselect">
                        <div
                          class="flex w-full items-center"
                          *ngFor="
                            let option of agreementSteps[currentAgreementStepIndex]?.options;
                            index as i
                          "
                          (click)="
                            addAgreementOption(agreementSteps[currentAgreementStepIndex], option)
                          "
                        >
                          <input
                            id="{{ 'inputOption' + i }}"
                            style="margin: 5px 10px; float: left"
                            type="checkbox"
                            [checked]="option.selected"
                          />
                          <label class="mb-0">
                            {{ option.label }}
                          </label>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!agreementSteps[currentAgreementStepIndex]?.multiselect">
                        <div class="col-xs-12 col-sm-12 col-md-12 mt-2.5 mb-2.5">
                          <table class="factors-table" style="width: 100%">
                            <thead>
                              <tr>
                                <th class="text-left pt-1 pb-2.5 pl-1 pr-1">Usage</th>
                                <th class="text-left pt-1 pb-2.5 pl-1 pr-1">Term</th>
                                <th
                                  *ngIf="
                                    track &&
                                    isAllowed([], ['UpdateTransaction:RestrictedLicenseRequester'])
                                  "
                                  class="text-right pt-1 pb-2.5 pl-1 pr-1"
                                >
                                  Price
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container>
                                <tr *ngFor="let option of agreementProcessOptions">
                                  <td class="pt-1 pb-1 pl-1 pr-1">
                                    <b style="font-weight: 600">{{ option.label }}</b>
                                  </td>
                                  <td class="pt-1 pb-1">
                                    <select
                                      [(ngModel)]="option.term"
                                      (change)="onAgreementOptionProcessChange(option, $event)"
                                      style="width: 100%"
                                    >
                                      <option hidden value="" disabled selected>Select..</option>
                                      <option
                                        value="{{ sOption.label }}"
                                        [disabled]="
                                          option.data[sOption.columnIndex].type === 'not-apply'
                                        "
                                        *ngFor="
                                          let sOption of agreementSteps[currentAgreementStepIndex]
                                            .options
                                        "
                                      >
                                        {{ sOption.label }}
                                      </option>
                                    </select>
                                  </td>
                                  <td
                                    *ngIf="
                                      track &&
                                      isAllowed(
                                        [],
                                        ['UpdateTransaction:RestrictedLicenseRequester']
                                      )
                                    "
                                    class="text-right"
                                    style="min-width: 100px"
                                  >
                                    {{
                                      option.price?.type === 'number'
                                        ? (option.price?.value
                                            | currency : 'USD' : 'symbol' : '0.0') + ' USD'
                                        : option.price?.value
                                    }}
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </div>
                      </ng-container>
                    </div>
                    <div class="clearfix" *ngIf="showAgreementLicenseTerms" [@inOutAnimation]>
                      <div class="row text-left mt-0">
                        <div class="col-xs-12 col-sm-12 col-md-12 overflow-auto">
                          <div class="form-group ml-2.5 mr-2.5">
                            <h5>License Review</h5>
                            <table class="factors-table mt-4" style="width: 100%">
                              <thead>
                                <tr>
                                  <th class="text-left pt-1 pb-2.5 pl-1 pr-1">Usage</th>
                                  <th class="text-left pt-1 pb-2.5 pl-1 pr-1">Term</th>
                                  <th
                                    class="text-right pt-1 pb-2.5 pl-1 pr-1"
                                    *ngIf="
                                      isAllowed(
                                        [],
                                        ['UpdateTransaction:RestrictedLicenseRequester']
                                      )
                                    "
                                  >
                                    {{ editOnly ? 'Song Agreement' : 'Price' }}
                                  </th>
                                  <th *ngIf="editOnly" class="text-right pt-1 pb-2.5 pl-1 pr-1">
                                    Others
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngIf="agreementLicenseTerms && agreementLicenseTerms.factors"
                                >
                                  <tr *ngFor="let factor of agreementLicenseTerms.factors">
                                    <td class="text-left pt-1 pb-1 pl-1 pr-1">
                                      <b>
                                        {{ factor.label }}
                                      </b>
                                    </td>
                                    <td class="text-left pt-1 pb-1 pl-1 pr-1">
                                      <b>
                                        {{ factor.term }}
                                      </b>
                                    </td>
                                    <td
                                      class="text-right pt-1 pb-1 pl-1 pr-1"
                                      style="min-width: 100px"
                                      *ngIf="
                                        isAllowed(
                                          [],
                                          ['UpdateTransaction:RestrictedLicenseRequester']
                                        )
                                      "
                                    >
                                      <b
                                        *ngIf="
                                          isAllowed([], ['UpdateTransactionAll']) ||
                                          (isAllowed(['UpdateTransactionAll']) && !factor.editable)
                                        "
                                      >
                                        {{
                                          factor.price.type === 'number'
                                            ? (factor.price.value
                                                | currency : 'USD' : 'symbol' : '0.0') + ' USD'
                                            : factor.price.value
                                        }}
                                      </b>
                                    </td>
                                    <td *ngIf="editOnly" class="text-right">
                                      <b>Ask For Quote</b>
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                            <hr />
                            <div align="right" class="mt-5 ml-1.5 mr-1">
                              <strong
                                *ngIf="
                                  agreementLicenseTerms &&
                                  isAllowed([], ['UpdateTransaction:RestrictedLicenseRequester'])
                                "
                              >
                                Total:
                                {{
                                  agreementLicenseTerms.total === 'Quote Pending'
                                    ? agreementLicenseTerms.total
                                    : (agreementLicenseTerms.total
                                        | currency : 'USD' : 'symbol' : '0.0') + ' USD'
                                }}
                              </strong>
                            </div>
                          </div>
                          <ng-container *ngIf="transactionInputFactors && track?.restriction">
                            <br />
                            <p>
                              <span class="fw500">Artist's restrictions:</span>
                              {{ track.restriction }}
                            </p>
                          </ng-container>
                          <div
                            class="form-group pull-right mt-8 mb-0"
                            [ngClass]="{
                              'pull-right': transactionInputFactors,
                              'text-center': !transactionInputFactors
                            }"
                          >
                            <button
                              class="mr-2.5 btn outline-primary-btn mb-4"
                              *ngIf="transactionInputFactors && !trackInput"
                              (click)="updateData()"
                              [disabled]="loadingRequest || invalidPrice"
                            >
                              Save changes
                              <i class="spin medium" *ngIf="editSpinActive"></i>
                            </button>
                            <ng-container *ngIf="!editOnly">
                              <button
                                class="mr-2.5 btn outline-primary-btn mb-4"
                                *ngIf="track"
                                (click)="sendAgreementLicenseRequest(false)"
                              >
                                Add and continue search
                                <i class="spin medium" *ngIf="editSpinActive"></i>
                              </button>
                              <button
                                id="continueDraftButton"
                                class="btn primary-btn mb-4"
                                (click)="sendAgreementLicenseRequest(true)"
                                [disabled]="loadingRequest || invalidPrice"
                              >
                                {{
                                  transactionAction
                                    ? transactionAction.label
                                    : 'Continue to License Request'
                                }}
                                <i class="spin medium" *ngIf="submitSpinActive"></i>
                              </button>
                              <br />
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <button
                        *ngIf="currentAgreementStepIndex > 0 || showLicenseTerms"
                        [@inOutAnimation]
                        type="button"
                        class="btn secondary-link mb-0 pull-left pl-1"
                        (click)="prevAgreementStep()"
                      >
                        <i
                          class="fa fa-angle-left"
                          aria-hidden="true"
                          style="font-size: 17px; font-weight: bold; padding-right: 5px"
                        ></i>
                        {{
                          transactionInputFactors && showAgreementLicenseTerms
                            ? 'Go to Edit license'
                            : 'Prev'
                        }}
                      </button>
                      <button
                        id="nextAgreementButton"
                        *ngIf="
                          this.currentAgreementStepIndex !== this.agreementSteps?.length - 1 &&
                          !showAgreementLicenseTerms
                        "
                        type="button"
                        class="btn secondary-link mb-0 pull-right pr-1"
                        (click)="nextAgreementStep()"
                        [disabled]="agreementProcessOptions.length === 0"
                      >
                        Next
                        <i
                          class="fa fa-angle-right"
                          aria-hidden="true"
                          style="font-size: 17px; font-weight: bold; padding-left: 5px"
                        ></i>
                      </button>
                      <button
                        id="continueAgreementButton"
                        type="button"
                        class="btn secondary-link mb-0 pull-right pr-1"
                        *ngIf="
                          this.currentAgreementStepIndex === this.agreementSteps?.length - 1 &&
                          !showAgreementLicenseTerms
                        "
                        (click)="endAgreementProcess()"
                      >
                        Continue
                      </button>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div
            class="modal-footer"
            *ngIf="
              !transactionInputFactors &&
              isAllowed([], ['UpdateTransaction:RestrictedLicenseRequester'])
            "
          >
            <div class="col-xs-12 col-sm-12 col-md-12 text-center">
              <p style="font-weight: bold" class="inline pl-5">
                Can't Find the Terms You Need?&nbsp;
              </p>
              <p class="inline mt-2.5">Submit a&nbsp;</p>
              <button
                type="button"
                class="secondary-link mb-0 pl-0"
                (click)="this.showCustomProcess()"
              >
                Custom Terms Request!
              </button>
            </div>
            <div *ngIf="isAllowed(['*', 'ListTiers'], []) && !assignationData?.initOwnerUid">
              <button class="btn primary-btn sm-btn mt-2.5" (click)="chooseUserUID()">
                Admin License assignation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-license-assignation-modal
  (sendAssignationData)="getAssignationData($event)"
  #licenseAssignationModalComponent
>
</app-license-assignation-modal>
<app-custom-license-music-request-modal
  #customLicenseMusicRequestModalComponent
  [track]="this.track"
  (showRegularProcess)="this.showModal()"
  (assignationCheck)="assignationCheck($event)"
></app-custom-license-music-request-modal>
<app-song-status-modal
  #songStatusModalComponent
  [tracks]="[track]"
  (restrictionsAgreed)="onRestrictionsAgreed()"
  (cancelStatusModal)="assignationData = {}; this.hideModal()"
>
</app-song-status-modal>
<app-song-restriction-modal
  #songRestrictionModalComponent
  (restrictionsAgreed)="onRestrictionsAgreed()"
></app-song-restriction-modal>
