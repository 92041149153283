import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TransactionsService } from 'sostereo-services';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { CommonService } from '../../../../shared/services/common.service';
import { transition, trigger, useAnimation } from '@angular/animations';
import { inOutAnimation } from '../../../../shared/animations/in-out.animation';
import { finalize } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { cloneDeep, clone, remove } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { GenericModalService } from 'src/app/angular/shared/services/generic-modal.service';
import { environment } from 'src/environments/environment';
import { LicenseAssignationModalComponent } from '../license-assignation-modal/license-assignation-modal.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TagsInputDropdownComponent } from '../../../../shared/components/tags-input-dropdown/tags-input-dropdown.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { BtnComponent } from 'src/app/components/btn/btn.component';
import { InputFieldComponent } from 'src/app/components/input-field/input-field.component';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-custom-license-music-request-modal',
  templateUrl: './custom-license-music-request-modal.component.html',
  styleUrls: ['./custom-license-music-request-modal.component.scss'],
  animations: [trigger('inOutAnimation', [transition(':enter', useAnimation(inOutAnimation))])],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    TagsInputDropdownComponent,
    NgFor,
    AlertModule,
    LicenseAssignationModalComponent,
    BtnComponent,
    InputFieldComponent,
    MatTooltip,
  ],
})
export class CustomLicenseMusicRequestModalComponent implements OnInit {
  @ViewChild('customLicenseMusicRequestModal', { static: true }) modalTemplate: TemplateRef<any>;
  @ViewChild('licenseAssignationModalComponent', { static: true })
  licenseAssignationModalComponent: LicenseAssignationModalComponent;

  @Input()
  public track;

  @Input()
  public transactionInput;

  @Input()
  public editOnly;

  @Input()
  public isDraft;

  @Input()
  public licenseAssignation;

  @Output()
  public showRegularProcess = new EventEmitter();

  @Input()
  public transactionAction;

  @Output()
  transactionUpdate = new EventEmitter();

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onModalClose = new EventEmitter();

  @Output()
  assignationCheck = new EventEmitter();

  public customLicenseRequestForm: FormGroup;
  public modalRef: BsModalRef;
  public loadingRequest = false;
  public editSpinActive = false;
  public submitSpinActive = false;
  public requestSuccess = false;
  alerts: any[] = [];
  public invalidPrice = false;
  public transactionComplete: any;
  public transactionInputFactors: any;
  public transactionOld: any = null;
  private licenseDraft = false;
  categoryModel = '';
  public categories = [
    { slug: 'commercial', name: 'Commercial / Advertisement / Branded Content', visible: true },
    { slug: 'films', name: 'TV-Show / Films', visible: true },
    { slug: 'videoGame', name: 'Video Game / In-App', visible: true },
  ];

  public medias = [
    { slug: 'internal', name: 'Internal', visible: true },
    { slug: 'industrial', name: 'Industrial', visible: true },
    { slug: 'social', name: 'Social Media', visible: true },
    { slug: 'online', name: 'Online/Digital', visible: true },
    { slug: 'ctv', name: 'OTT & CTV', visible: true },
    { slug: 'terrestrial', name: 'Terrestrial Radio & Streaming Radio', visible: true },
    { slug: 'broadcast', name: 'Broadcast TV', visible: true },
    { slug: 'promo', name: 'Promo', visible: true },
    { slug: 'themeSong', name: 'Theme song', visible: true },
    { slug: 'inSceneUsage', name: 'In-scene usage', visible: true },
    { slug: 'openingCredits', name: 'Opening credits', visible: true },
    { slug: 'closingCredits', name: 'Closing credits', visible: true },
  ];

  public terms = [
    { slug: '1month', name: '1 Month', visible: true },
    { slug: '3months', name: '3 Months', visible: true },
    { slug: '6months', name: '6 Months', visible: true },
    { slug: '9months', name: '9 Months', visible: true },
    { slug: '1year', name: '1 Year', visible: true },
    { slug: 'perpetuity', name: 'Perpetuity', visible: true },
  ];

  public mediaOptions: any = [];

  public territories = [
    { slug: 'usa', name: 'USA', visible: true },
    { slug: 'northAmerica', name: 'North America', visible: true },
    { slug: 'worldwide', name: 'Worldwide', visible: true },
    { slug: 'uk', name: 'UK', visible: true },
    { slug: 'germany', name: 'Germany', visible: true },
    { slug: 'europe', name: 'Europe', visible: true },
    { slug: 'latam', name: 'LATAM', visible: true },
  ];

  public draftFields: any = {};
  public domainName = environment.name;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private transactionsService: TransactionsService,
    private trackingService: TrackingService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private router: Router,
    private genericModalService: GenericModalService,
  ) {}

  ngOnInit() {
    if (localStorage.getItem('SOSTEREO.licenseAssignation')) {
      this.licenseAssignation = JSON.parse(localStorage.getItem('SOSTEREO.licenseAssignation'));
      this.licenseDraft = true;
    }
    this.customLicenseRequestForm = this.formBuilder.group({
      category: new FormControl(null, { validators: Validators.required }),
      media: new FormControl(null, { validators: Validators.required }),
      paidMedia: new FormControl(true, { validators: Validators.required }),
      term: new FormControl(null, { validators: Validators.required }),
      territory: new FormControl(null, { validators: Validators.required }),
      duration: new FormControl(null, { validators: Validators.required }),
      spots: new FormControl(null, { validators: Validators.required }),
      typeOfUse: new FormControl(null),
      price: this.formBuilder.group({
        currency: new FormControl(null),
        total: new FormControl(null),
      }),
    });
    this.onChanges();
  }

  // Map it to display tags in the tags-input-dropdown.component
  mapTags(license) {
    const licenseData = license.items[0].license[0];
    this.customLicenseRequestForm.patchValue({
      category: licenseData.category.label,
      media: licenseData.media.label,
      term: licenseData.term.label,
      territory: licenseData.territory.label,
    });
    this.draftFields = {
      category: [licenseData.category.label],
      media: [],
      term: [],
      territory: [],
    };
    const medias = licenseData.media.label.split('; ');
    medias.forEach((m, idx) => {
      const term = licenseData?.term?.label?.split('; ')[idx]?.split(` for ${m}`)[0];
      const territory = licenseData?.territory?.label?.split('; ')[idx]?.split(` for ${m}`)[0];
      this.mediaOptions.push({
        name: m,
        media: {
          name: m,
        },
        term: {
          name: term,
        },
        territory: { name: territory },
      });

      this.draftFields.media.push(m);
      this.draftFields.term.push(term);
      this.draftFields.territory.push(territory);
    });
    console.log(
      'Maps tags >>> ',
      this.draftFields,
      this.customLicenseRequestForm.value,
      this.mediaOptions,
    );
  }

  public showModal() {
    this.transactionOld = this.transactionsService.getTransactionDraft();
    this.transactionComplete = this.transactionInput
      ? cloneDeep(this.transactionInput)
      : this.transactionOld
      ? cloneDeep(this.transactionOld)
      : null;
    if (this.transactionComplete) {
      this.mapTags(this.transactionComplete);
    }
    this.transactionInputFactors = this.transactionInput
      ? this.mapTransactionToFactors(this.transactionInput)
      : this.transactionOld
      ? this.mapTransactionToFactors(this.transactionOld)
      : null;
    if (this.transactionInputFactors) {
      for (const prop in this.transactionInputFactors.factors[0]) {
        if (this.customLicenseRequestForm.controls[prop]) {
          if (prop === 'paidMedia') {
            const valueCheck: boolean =
              this.transactionInputFactors.factors[0][prop] == 'Yes' ? true : false;
            this.customLicenseRequestForm.controls[prop].setValue(valueCheck);
          } else {
            if (this.transactionInputFactors.factors[0][prop].hasOwnProperty('label')) {
              this.customLicenseRequestForm.controls[prop].setValue(
                this.transactionInputFactors.factors[0][prop].label,
              );
            } else {
              this.customLicenseRequestForm.controls[prop].setValue(
                this.transactionInputFactors.factors[0][prop],
              );
            }
          }
        }
      }
      this.customLicenseRequestForm.controls.price.setValue(
        this.transactionInputFactors.factors[0].amount,
      );
    }
    let cssClasses = 'custom-license-modal';
    if (this.domainName === 'sostereo') {
      cssClasses = cssClasses + ' modal-lg';
    } else {
      cssClasses = cssClasses + ' modal-md';
    }

    setTimeout(() => {
      this.modalRef = this.modalService.show(this.modalTemplate, {
        class: cssClasses,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
      });
    }, 200);

    this.trackingService.track(
      'Custom License Request Modal Show',
      {
        action: 'Modal Show',
        kind: 'Custom License Request modal',
      },
      {
        event_action: 'Custom licence request modal opened',
        event_type: 'Modal Opened',
      },
    );
  }

  public closeModal() {
    this.customLicenseRequestForm.reset({ paidMedia: true });
    this.requestSuccess = false;
    this.loadingRequest = false;
    this.editSpinActive = false;
    this.submitSpinActive = false;
    this.modalRef?.hide();
    this.onModalClose.emit();
    this.trackingService.track(
      'Custom License Request Modal Hide',
      {
        action: 'Modal Hide',
        kind: 'Custom License Request modal',
      },
      {
        event_action: 'Custom license request modal hidden',
        event_type: 'Modal Hidden',
      },
    );
    if (!this.licenseDraft) {
      this.licenseAssignation = {};
    }
    this.mediaOptions = [];
    this.draftFields = {};
  }

  public openRegularProcess() {
    this.customLicenseRequestForm.reset({ paidMedia: true });
    this.modalRef.hide();
    this.showRegularProcess.emit();
  }

  private buildLicenseTerms(license: any) {
    const newTransaction = {};
    Object.keys(license).forEach((key) => {
      if (license[key] || key.toLocaleLowerCase() === 'paidmedia') {
        if (key.toLocaleLowerCase() === 'paidmedia') {
          newTransaction[key] = license[key] ? 'Yes' : 'No';
        } else if (key.toLocaleLowerCase() === 'spots') {
          newTransaction[key] = license[key];
        } else {
          if (!license[key]?.hasOwnProperty('label')) {
            newTransaction[key] = {
              label: license[key],
              title: key,
            };
          } else {
            newTransaction[key] = license[key];
          }
        }
      }
    });

    return newTransaction;
  }

  private mapTransaction(license) {
    const newTransaction = this.buildLicenseTerms(license);
    // debugger;
    let newSong = null;
    let transaction: any = null;
    if (this.transactionInputFactors) {
      transaction = this.transactionComplete;
    } else {
      transaction = {
        description: 'x',
        licenseType: 'custom',
        amount: {
          total:
            license.price && license.price.total && !isNaN(license.price.total)
              ? license.price.total
              : 'Quote Pending',
          currency: 'USD',
        },
        items: [],
      };
    }

    if (this.track) {
      newSong = {
        artists: this.track.artists,
        name: this.track.name,
        soId: this.track.soId,
        songVersion: this.track.songVersion,
        id: this.track._id || this.track.trackId,
        files: this.track.files || this.track.fileUrls,
        stems: this.track.stems || [],
        restriction: this.track.restriction,
        restrictions: this.track.restrictions,
        explicit: this.track.explicit || false,
        license: [],
      };
      transaction.items.push(newSong);
    }

    let licenseTagObj: any = {
      category: {
        label:
          this.customLicenseRequestForm.value.category.name ||
          this.customLicenseRequestForm.value.category,
        title: 'category',
      },
      media: {
        label: '',
        title: 'media',
      },
      term: {
        label: '',
        title: 'term',
      },
      territory: {
        label: '',
        title: 'territory',
      },
    };

    this.mediaOptions.forEach((m) => {
      licenseTagObj.media.label = licenseTagObj.media.label
        ? licenseTagObj.media.label + '; ' + m.name
        : m.name;
      licenseTagObj.term.label = licenseTagObj.term.label
        ? licenseTagObj.term.label + '; ' + `${m.term.name} for ${m.name}`
        : `${m.term.name} for ${m.name}`;
      licenseTagObj.territory.label = licenseTagObj.territory.label
        ? licenseTagObj.territory.label + '; ' + `${m.territory.name} for ${m.name}`
        : `${m.territory.name} for ${m.name}`;
    });

    transaction.items.forEach((song) => {
      song.license = [
        {
          ...newTransaction,
          ...licenseTagObj,
          amount: song.license[0]?.amount
            ? song.license[0].amount
            : {
                total: license.price && license.price.total ? license.price.total : 'Ask For Quote',
                currency:
                  license.price && license.price.currency ? license.price.currency : 'no-quote',
              },
        },
      ];
    });
    return transaction;
  }

  onCustomLicenseRequestSubmit(redirectDraft: boolean) {
    if (this.customLicenseRequestForm.valid) {
      const transaction = this.mapTransaction(this.customLicenseRequestForm.value);
      console.log('Send License >> ', transaction, this.mediaOptions);
      this.loadingRequest = true;
      this.submitSpinActive = true;
      this.transactionsService.setTransactionDraft(transaction);
      this.trackingService.track(
        'Transaction Draft Created',
        {
          data: transaction,
        },
        {
          event_action: 'Transaction draft created',
          event_type: 'Transaction Draft',
          event_value: transaction?.amount.total + ' ' + transaction?.amount.currency,
          element_type: transaction?.licenseType,
          element_value: transaction?.description,
        },
      );
      if (
        this.licenseAssignation?.initOwnerUid &&
        !localStorage.getItem('SOSTEREO.licenseAssignation')
      ) {
        localStorage.setItem(
          'SOSTEREO.licenseAssignation',
          JSON.stringify(this.licenseAssignation),
        );
        this.licenseDraft = true;
      }
      if (redirectDraft) {
        this.router.navigateByUrl('/transactions/draft');
      } else {
        const activeToast = this.toastr.success(
          '<p>Song added successfully</p><a class="textDecoration"> Go to Draft </a>',
          'License',
          { enableHtml: true },
        );
        activeToast.onTap.subscribe(() => {
          this.router.navigateByUrl('/transactions/draft');
        });
      }
      this.requestSuccess = true;
      this.closeModal();
    } else {
      this.customLicenseRequestForm.markAllAsTouched();
    }
  }

  public confirmationModal(transaction) {
    this.modalRef.hide();
    setTimeout(() => {
      this.genericModalService.showModal(
        {
          title: this.transactionAction ? this.transactionAction.label : 'Submit license request',
          message: `Are you sure you want to submit the transaction?`,
          auxMessage: '',
          cssClass: 'fa fa-exclamation-triangle',
          size: 'modal-md',
          hideCloseBtn: true,
          ignoreBackdropClick: true,
          btns: [
            {
              status: true,
              data: { type: 'accept', transaction: transaction },
              cssClass: 'primary-btn',
              title: 'Accept',
            },
            {
              status: true,
              kind: 'close',
              title: 'Cancel',
              cssClass: 'cancel-btn',
              data: { type: 'cancel' },
            },
          ],
        },
        this.modalClosed.bind(this),
      );
    }, 1);
  }

  public modalClosed(event) {
    if (event && event.data) {
      if (event.data.type === 'cancel') {
        this.loadingRequest = false;
        this.submitSpinActive = false;
        this.modalRef = this.modalService.show(this.modalTemplate, {
          class: 'modal-md',
          ignoreBackdropClick: true,
          keyboard: false,
        });
      } else if (event.data.type === 'accept') {
        this.modalRef = this.modalService.show(this.modalTemplate, {
          class: 'modal-md',
          ignoreBackdropClick: true,
          keyboard: false,
        });
        event.data.transaction.action = 'APPROVED';
        this.updateTransaction(event.data.transaction, 'License request submitted');
      }
    }
  }

  public updateData() {
    if (this.customLicenseRequestForm.valid) {
      const transaction = this.mapTransaction(this.customLicenseRequestForm.value);
      if (this.isDraft) {
        this.transactionsService.setTransactionDraft(transaction);
        this.closeModal();
        this.transactionUpdate.emit(transaction);
      } else {
        this.loadingRequest = true;
        this.editSpinActive = true;
        const transactionUpdate = {
          action: 'EDIT',
          items: transaction.items,
        };
        this.updateTransaction(transactionUpdate, 'Data updated');
      }
    } else {
      this.toastr.warning("There are fields that aren't filled in", 'Complete Fields');
    }
  }

  private updateTransaction(transaction, message) {
    console.log('Update Transaction >>> ', transaction, this.transactionInputFactors, this.track);
    this.transactionsService
      .update(this.transactionInputFactors._id, transaction)
      .pipe(
        finalize(() => {
          this.loadingRequest = false;
          this.editSpinActive = false;
          this.submitSpinActive = false;
        }),
      )
      .subscribe({
        next: (res) => {
          console.log('transactionsService Response >>> ', res);
          this.toastr.success(message, 'Sucess');
          if (transaction && transaction.action === 'APPROVED') {
            this.trackingService.track('Transaction approved', {
              transaction: res?.data,
              status: res?.data ? res?.data?.status : res,
              transactionId: res?.data ? res?.data._id : this.transactionInputFactors?._id,
            });
          }
          this.closeModal();
          this.transactionUpdate.emit(res.data);
        },
        error: (err) => {
          console.log('transactionsService Error >>>', err);
          this.toastr.error(err.error ? err.error.message : err.message, 'Error');
          this.trackingService.track('Error updating transaction', {
            transaction: transaction,
            transactionId: this.transactionInputFactors?._id,
            error: err,
          });
        },
      });
  }

  public isAllowed(allowed, disallowed?) {
    return this.commonService.isAllowed(allowed, disallowed);
  }

  onChanges(): void {
    this.customLicenseRequestForm.get('price.total').valueChanges.subscribe((val) => {
      this.invalidPrice = false;
      if (val === null) {
        this.customLicenseRequestForm.controls.price.setValue({
          currency: 'no-quote',
          total: 'Ask For Quote',
        });
      }
    });
  }

  public isNumeric(num) {
    return !isNaN(num);
  }

  mapTransactionToFactors(transaction: any): any {
    const transactionRow = transaction;
    const transactionData: any = {
      _id: transactionRow._id,
      factors: [],
    };
    if (transactionRow.items[0].agreement) {
      transactionRow.items[0].license.forEach((lic) => {
        transactionData.factors.push({
          label: lic.category.label,
          parent: 'usage',
          price: {
            value: isNaN(lic.amount.total) ? lic.amount.total : Number(lic.amount.total),
            type: lic.amount.currency === 'USD' ? 'number' : 'no-quote',
          },
          selected: true,
          term: lic.term.label,
        });
      });
      transactionData.total = Number(
        transactionRow.amount.total.toString().replace(',', '').replace('$', ''),
      );
      transactionData.total = isNaN(transactionData.total)
        ? 'Quote Pending'
        : transactionData.total;
    } else {
      transactionData.factors = transactionRow.items[0].license;
    }
    if (transactionRow.items[0].agreement) {
      transactionData.agreement = transactionRow.items[0].agreement;
    }
    // This is to add the songAgreement flag to know if the song has agreement or not if the use does have
    if (
      transaction.items[0].hasOwnProperty('agreement') &&
      transaction.items[0].agreement !== null
    ) {
      transactionData.songAgreement = clone(transaction.items[0].agreement);
    }
    console.log('transactionData >>> ', transactionData, transactionRow);
    if (transactionData.statusObj) {
      delete transactionData.statusObj;
    }
    console.log('Transaction Data >>> ', transactionData);

    return transactionData;
  }

  public addTag(tag, kind, index?) {
    console.log('Add tag >>> ', tag, kind);
    let updateData: any = {};
    if (
      kind === 'category' &&
      !tag?.name.toLowerCase().includes('film') &&
      !tag?.name.toLowerCase().includes('game')
    ) {
      updateData.typeOfUse = null;
    }
    updateData[kind] = tag;
    // Creates a new media field that will create a new term and territory option so the form will be invalid
    if (kind === 'media') {
      this.mediaOptions.push(tag);
      updateData.term = null;
      updateData.territory = null;
    }
    // term and territory field to add it to mediaOptions
    if (index !== undefined) {
      this.mediaOptions[index][kind] = tag;
    }
    // To make field in form valid if it's filled for all the media tags
    if (kind === 'term' || kind === 'territory') {
      const filter = this.mediaOptions.filter((m) => m[kind] !== undefined);
      if (filter.length === this.mediaOptions.length) {
        this.customLicenseRequestForm.patchValue(updateData);
      }
    } else {
      this.customLicenseRequestForm.patchValue(updateData);
    }
    this.draftFields = {};
  }

  public removeTag(tag, kind, index?) {
    console.log('Remove >>> ', tag, kind);
    let updateData: any = {};
    if (kind === 'category') {
      updateData.typeOfUse = null;
    }
    if (kind === 'media') {
      remove(this.mediaOptions, (option: any) => option.name === tag);
    }
    if ((kind === 'media' && this.mediaOptions.length === 0) || kind !== 'media') {
      updateData[kind] = null;
      this.customLicenseRequestForm.patchValue(updateData);
    }
    // term and territory field to remove it from mediaOptions
    if (index !== undefined) {
      delete this.mediaOptions[index][kind];
    }
    this.draftFields = {};
  }

  public chooseUserUID() {
    this.licenseAssignationModalComponent.showModal();
    this.closeModal();
  }

  getAssignationData($event) {
    this.licenseAssignation = $event;
    if (this.licenseAssignation?.agreement) {
      this.closeModal();
      this.assignationCheck.emit($event);
    } else {
      this.showModal();
    }
  }

  getControl(name): FormControl {
    return this.customLicenseRequestForm.get(name) as FormControl;
  }
}
